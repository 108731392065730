import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/DashboardView.vue'
import EventsView from '../views/EventsView'
import DeviceStatusView from '../views/DeviceStatusView'
import JobStatusView from '../views/JobStatusView'
import JobHistoryView from '../views/JobHistoryView'
import MaintenanceView from '@/views/MaintenanceView'
import ManageProfileView from '@/views/ManageProfileView'
import MachineManagementView from '@/views/MachineManagementView'
import MaintenanceSettingsView from '@/views/MaintenanceSettingsView'
import EventSettingsView from '@/views/EventSettingsView'
import SystemSetupView from '@/views/SystemSetupView'
import ManageOperatorsView from'@/views/ManageOperatorsView'
import ManageUsersView from '@/views/ManageUsersView'
import DeviceUsageView from '@/views/DeviceUsageView'
import ReportsView from '@/views/ReportView'
import BuyMyRotoView from '@/views/BuyMyRotoView'
import AdminLoginView from '@/views/adminViews/AdminLoginView'
import AdminDashboardView from '@/views/adminViews/AdminDashboardView'
import AdminStaffUserView from '@/views/adminViews/AdminStaffUserView'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    props: route => ({ query: route.query.q, currURL: route.query.currURL }),
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/events',
    name: 'Events',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/EventsView.vue')
    component: EventsView,
    meta: {
      title: 'Events Dashboard',
    },
  },
  {
    path: '/devicestatus',
    name: 'DeviceStatus',
    component: DeviceStatusView,
    meta: {
      title: 'Device Status',
    },
  },
  {
    path: '/jobstatus',
    name: 'JobStatus',
    component: JobStatusView,
    meta: {
      title: 'Job Status',
    },
  },
  {
    path: '/jobhistory/:jobId?',
    name: 'JobHistory',
    props: true,
    component: JobHistoryView,
    meta: {
      title: 'Job History',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: MaintenanceView,
    meta: {
      title: 'Maintenance Dashboard',
    },
  },
  {
    path: '/manageprofile',
    name: 'ManageProfile',
    component: ManageProfileView,
    meta: {
      title: 'ManageProfile',
    },
  },
  {
    path: '/managemacines',
    name: 'ManageMachines',
    component: MachineManagementView,
    meta: {
      title: 'Machine Management',
    },
  },
  {
    path: '/maintenancesettings',
    name: 'Maintenance Settings',
    component: MaintenanceSettingsView,
    meta: {
      title: 'Maintenance Settings',
    },
  },
  {
    path: '/eventsettings',
    name: 'Event Settings',
    component: EventSettingsView,
    meta: {
      title: 'Event Settings',
    },
  },
  {
    path: '/systemsetup',
    name: 'System Setup',
    component: SystemSetupView,
    meta: {
      title: 'IOT System Setup',
    },
  },
  {
    path: '/manageoperators',
    name: 'ManageOperators',
    component: ManageOperatorsView,
    meta: {
      title: 'Manage Operators',
    },
  },
  {
    path: '/manageusers',
    name: 'ManageUsers',
    component: ManageUsersView,
    meta: {
      title: 'Manage Users',
    },
  },
  {
    path: '/deviceusage',
    name: 'DeviceUsage',
    component: DeviceUsageView,
    meta: {
      title: 'Manage Users',
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: ReportsView,
    meta: {
      title: 'Reports',
    },
  },
  {
    path: '/buymyroto/:sn',
    name: 'BuyMyRoto',
    component: BuyMyRotoView,
    props: true,
    meta: {
      title: 'Buy MyRoto',
    },
  },
  {
    path: '/adminlogin',
    name: 'AdminLogin',
    component: AdminLoginView,
    meta: {
      title: 'Admin Login',
    },
  },
  {
    path: '/admindashboard',
    name: 'AdminDashboard',
    component: AdminDashboardView,
    meta: {
      title: 'Admin Dashboard',
    },
  },
  {
    path: '/managestaffusers',
    name: 'ManageStaffUsers',
    component: AdminStaffUserView,
    meta: {
      title: 'Manage Staff Users',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ?? 'Maxcess Cloud'
});

export default router
