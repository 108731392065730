<script setup>

    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import LoaderComponent from '../components/LoaderComponent.vue'

    import $ from 'jquery'
    import { ref,onMounted } from 'vue'
    import axios from 'axios';
    import { useStore } from 'vuex'
    
    import router from '@/router';
    import { toast } from "vue3-toastify";

    const store = useStore()

    var machineData = ref([])
    var loaderShow = ref(true)

    async function submitCheckbox(serial,index){
        console.log("here",serial)
        console.log("ht",document.getElementById(serial).checked)
        // console.log("t",$(`#${serial}`).is(':checked'))

        var data = await updateMachineData(serial,!(document.getElementById(serial).checked))
        if(data.status == 200){
            console.log("t1",document.getElementById(serial).checked)
            if(document.getElementById(serial).checked){
                console.log("inside checked")
                document.getElementById("toggle-"+serial).classList.remove('button-secondary',"off")
                document.getElementById("toggle-"+serial).classList.add('button-success')
                // $(`#${serial}`).prop("checked",false)
                machineData.value[index]["machine_active"] = true
            }else{
                console.log("inside unchecked")
                document.getElementById("toggle-"+serial).classList.remove('button-success')
                document.getElementById("toggle-"+serial).classList.add('button-secondary","off')
                // $(`#${serial}`).prop("checked",true)
                machineData.value[index]["machine_active"] = false
            }
        }
        
        console.log("t after",document.getElementById(serial).checked)
    }

    async function nameSubmit(serial){
        console.log("serial",serial)
        var machineName = $(`#machinename-${serial}`).val()
        var data = await updateMachineData(serial,null,machineName)
        if(data.status == 200){
            console.log(data)
        }
    }

    async function updateMachineData(serial,isActive=null,machineName=null) {
        loaderShow.value = true
        var returnData
        var clientId = store.getters.stateUser.associated_client
        if(clientId != null){

            try{
                if(machineName == ""){
                    throw("Machine name cannot be empty")
                }
                await axios.post('update_machines', { "machine_serial": serial, "is_active": isActive, "machine_name": machineName})
                .then(response => (
                    console.log("response in update_machines",response),
                    returnData = response,
                    loaderShow.value = false,
                    toast(response.data, {
                        autoClose: 3000,
                        theme: "colored",
                        type: "success",
                        position: "bottom-left",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    })
                    )
                )
            }catch(error){
                loaderShow.value = false
                toast(error.response.data.detail, {
                    autoClose: 3000,
                    theme: "colored",
                    type: "error",
                    position: "bottom-left",
                    transition: "slide",
                    dangerouslyHTMLString: true
                })
            }
            
        }else{
            router.push({ name: 'Home'})
        }

        return returnData

    }

    async function getMachineData() {

        var clientData = store.getters.stateUser
        if(clientData != null){
            await axios.post('get_machines', clientData)
                .then(response => (
                    console.log("response in machine management",response),
                    machineData.value = response.data
                )
            )

            loaderShow.value = false
        }else{
            router.push({ name: 'Home'})
        }

    }

    onMounted(() => {
        getMachineData()
    })

    
</script>

<template>
<LoaderComponent :show="loaderShow" />

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true"/>
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <div class="row">
            <div class="col-lg-4" v-for="(md,index) in machineData" :key="md.machine_serial">
                <div class="card text-center machine-card"  :style="[md.machine_active ? {'border-color': '#3ac47d'} : {'border-color': '#6c757d'}]">
                    <div class="card-header " :class="[md.machine_active ? 'machine-card-header' : 'machine-card-header-deactivated']">
                        {{ md.machine_name }}
                    </div>
                    <div class="card-body">

                        <div :id="`toggle-${md.machine_serial}`" class="toggle btn btn-success" :class="[md.machine_active ? 'button-success' : 'button-secondary off']"  role="button" style="width: 83.0625px; height: 33.1875px;">
                            <input :checked="md.machine_active" type="checkbox" :name="md.machine_serial" :id="md.machine_serial" class="chkToggle" >
                            <div class="toggle-group" @click="submitCheckbox(md.machine_serial,index);md.machine_active = !md.machine_active">
                                <label for="toggle1" class="btn btn-success toggle-on">Active</label>
                                <label for="toggle1" class="btn btn-secondary toggle-off">Inactive</label>
                                <span class="toggle-handle btn btn-light"></span>
                            </div>
                        </div>

                        <BForm>
                            <div class="row">
                                <div class="col-sm-8"><BFormInput :id="`machinename-${md.machine_serial}`" type="text" size="md" placeholder="New Name" required /></div>
                                <div class="col-sm-4"><BButton @click="nameSubmit(md.machine_serial)" class="form-btn" type="submit" variant="primary">Rename</BButton></div>
                            </div>
                                
                        </BForm>
                        <hr>
                        Serial Number: {{ md.machine_serial }}

                        <hr>

                        Last Reported: {{ md.last_reported }}
                    
                    </div>
                    
                </div>
            </div>
        </div>
      </div>
    </BCol>
  </BRow>
  
</BContainer>
</template>
