<script setup>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import DataTableComponent from '../components/DataTableComponent.vue'
    import LoaderComponent from '../components/LoaderComponent.vue'
    import router from '@/router';
    import { ref, onMounted, defineProps } from 'vue'
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    

    const props = defineProps({
      jobId: Number
    })

    var selectedJob = ref(null)
    var redirected = ref(false)
    var loaderShow = ref(true)

    

    var jobHistoryData = ref([])
    var jobsDropdown = ref([])

    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: false,
    }

    var columns = [
      { visible: false },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      // {  render : function ( data ) {
      //   return '<button class="btn btn-danger delete-btn"><i class="fa-solid fa-trash" style="color: #ffffff;"></i></button>'
      // }},
    ]

    async function getJobHistoryData() {

      console.log("selected job",selectedJob.value)
      // var clientId = store.getters.stateUser.associated_client
      if(store.getters.stateUser != null){
        console.log("job name is ",props.jobId,typeof props.jobId)
        var data = store.getters.stateUser
        if(props.jobId === undefined || props.jobId == ""){
          data.selected_job = selectedJob.value
        }else{
          if(selectedJob.value == null && !(redirected.value)){
            data.selected_job = Number(props.jobId)
            selectedJob.value = parseInt(props.jobId)
            redirected.value = true
          }
          else{
            data.selected_job = selectedJob.value
          }
          
        }
        
        axios.post('get_job_history', data)
          .then(response => (
          console.log("response",response),
          jobHistoryData.value = response.data.history_data,
          jobsDropdown.value = response.data.job_names
        ),
        
        )

        loaderShow.value = false
      }else{
        router.push({ name: 'Home'})
      }
    }


    onMounted(() => {
      getJobHistoryData();
    })

  //   var jobHistoryData= [
  //   ['EUR34', 'TEST1129///--..',  '11/28/2023 14:10:23',  '11/29/2023 14:28:01',  	'N/A',  '5m 5s',    '2hrs',  '4hrs', 'MOD22-01-45528',  '2', '']	,
  //   ['EUR34', 'TEST3.0701128',    '11/28/2023 14:07:48',  '11/29/2023 14:28:01',    'N/A', 	'5m 5s',    '3hrs',  '3hrs', 'MOD22-01-45528',	'1', '']	,
  //   ['Max',	  'TESTEUKF',         '11/28/2023 10:47:03',  '11/29/2023 14:28:01',    'N/A',  '5m 5s',	  '7hrs',  '2hrs', 'MOD22-01-45528',  '5', '']	,
  //   ['Max',	  'TEST3.070',        '11/17/2023 09:28:18',  '11/29/2023 14:28:01',    'N/A',  '5m 5s',    '1hr',  '6hrs', 'MOD22-01-45528',  '0', '']	,
  //   ['Max',	    'NOV16',	          '11/16/2023 16:26:01',  '11/29/2023 14:28:01',    'N/A',  '5m 5s',    '5hrs',  '2hrs', 'MOD22-01-45528',  '3', '']	,
  //   ['Max',	    'S',	              '11/16/2023 09:38:53',  '11/29/2023 14:28:01',    'N/A',  '5m 5s',    '4hrs',  '5hrs', 'MOD22-01-45528',  '0', ''],
  //   ['Max',	    'NOV9',	            '11/16/2023 09:43:38',  '11/29/2023 14:28:01',    'N/A',  '5m 5s',    '4hrs',  '3hrs', 'MOD22-01-45528',  '4', ''],
  // ]
</script>

<template>
<LoaderComponent :show="loaderShow" />
<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true"/>
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        
        <DataTableComponent :data="jobHistoryData" :columns="columns" :options="options">
            <template #card_heading>
                <h3 class="card-head-title">Manage Job History</h3>
                <div class="row">
                  <div class="col-lg-3">
                    <select @change="getJobHistoryData" v-model="selectedJob" class="form-select form-select-sm mb-3" aria-label="length-select">
                        <option :selected="selectedJob == null" :value="null">Please Select a Job</option>
                        <option v-for="job in jobsDropdown" :key="job.job_id" :value="job.job_id" :selected="selectedJob == job.job_id">{{ job.job_name }}</option>
                    </select>
                  </div>
                </div>
            </template>
            <template #table_header>
                <th>job_id</th>
                <th>Machine Name</th>
                <th>Job Name</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Operator Name</th>
                <th>Setup Time</th>
                <th>Jog Time</th>
                <th>Run Time</th>
                <th>Die Id</th>
                <th>Revolutions</th>
                <!-- <th>Action</th> -->
            </template>
        </DataTableComponent>


      </div>

      
    </BCol>
  </BRow>
  
</BContainer>
</template>

<script>
    
    // import { ref } from 'vue'
    

    // var jobHistoryData = ref("")

    export default {
        name: 'JobHistoryView',
        components: {
            DataTableComponent,
            SidebarComponent,
            NavbarComponent
            // HelloWorld
        },
        mounted () {
        },
        data() {
          return {
            
        };
      },

        methods:{
          
        }
    }
</script>