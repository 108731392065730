<script setup>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import LoaderComponent from '../components/LoaderComponent.vue'


    import { reactive,ref,nextTick,onMounted } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex'
    
    import router from '@/router';
    import { toast } from "vue3-toastify";

    const store = useStore()
    var loaderShow = ref(true)

    const form = reactive({
        ss_id: null,
        selected_timezone: null,
        operators: false,
        shifts: [],
        running_color: '#8fce00',
        stopped_color: '#f44336',
        current_timezone: null,
        client_id: store.getters.stateUser != null ? store.getters.stateUser.associated_client : null,
    })

    var timeZones = ref(null)

    var shiftLookup = []

    const show = ref(true)
    const onSubmit = (event) => {
        event.preventDefault()
        // alert(JSON.stringify(form))
        console.log("shifts",form.shifts)
        updateSystemSettings()
    }
    
    const onReset = (event) => {
        event.preventDefault()
        // Reset our form values
        // Trick to reset/clear native browser form validation state
        show.value = false
        nextTick(() => {
            show.value = true
        })
    }



    
    // const sample = require('../assets/helpers/timezones.json'); 
    // var timeZones = sample["timezones"]
    

    // function updateDateTime() {
    //     // create a new `Date` object
    //     const now = new Date();

    //     // get the current date and time as a string
    //     const currentDateTime = now.toLocaleString();

    //     // update the `textContent` property of the `span` element with the `id` of `datetime`
    //     document.getElementById('datetime').textContent = currentDateTime;
    // }

    // setInterval(updateDateTime, 1000);

    // var timenow = ref(Date().toLocaleString()) 
    var shiftCount = ref(1)

    function checkShiftStartInput(shiftName){
        console.log("here",shiftLookup,shiftName)
        console.log("formshift",form.shifts)
        for(var i=0;i<shiftLookup.length;i++){

            if(shiftLookup[i].shift_name == shiftName){
                console.log("1")
                var changedSTime = form.shifts[i].start_time.split(":")
                var changedETime = form.shifts[i].end_time.split(":")

                for(var j=0;j<form.shifts.length;j++){
                    if(i==j){ continue }
                    console.log("sl",shiftLookup[i].start_time,shiftLookup[i].end_time)
                    var currSTime = shiftLookup[j].start_time.split(":")
                    var currETime = shiftLookup[j].end_time.split(":")

                    console.log(currSTime,currETime,changedSTime,changedETime)

                    if(parseInt(currSTime[0]) < parseInt(changedSTime[0]) && parseInt(changedSTime[0]) < parseInt(currETime[0])){
                        // if(parseInt(currSTime[1]) <= parseInt(changedSTime[1]) ||  parseInt(changedETime[1]) <= parseInt(currETime[1])){
                            form.shifts[i].start_time = shiftLookup[i].start_time
                            alert("Two shifts cannot overlap 1")
                            break
                        // }
                    }
                    else if(parseInt(currSTime[0]) == parseInt(changedSTime[0]) && parseInt(currSTime[1]) <= parseInt(changedSTime[1])){
                        form.shifts[i].start_time = shiftLookup[i].start_time
                        alert("Two shifts cannot overlap 2")
                        break
                    }
                    else if(parseInt(currETime[0]) == parseInt(changedSTime[0]) && parseInt(currETime[1]) >= parseInt(changedSTime[1])){
                        form.shifts[i].start_time = shiftLookup[i].start_time
                        alert("Two shifts cannot overlap 3")
                        break
                    }else if(changedSTime[0] >= changedETime[0] && changedSTime[1] >= changedETime[1]){
                        form.shifts[i].start_time = shiftLookup[i].start_time
                        alert("Shift start time and end time cannot overlap 1")
                        break
                    }else {
                        shiftLookup[i].start_time = form.shifts[i].start_time
                    }
                }
                break
            }

        }
    }


    function checkShiftEndInput(shiftName){
        for(var i=0;i<shiftLookup.length;i++){

            if(shiftLookup[i].shift_name == shiftName){

                var changedSTime = form.shifts[i].start_time.split(":")
                var changedETime = form.shifts[i].end_time.split(":")

                for(var j=0;j<form.shifts.length;j++){
                    if(i==j){ continue }
                    console.log("sl",shiftLookup[i].start_time,shiftLookup[i].end_time)
                    var currSTime = shiftLookup[j].start_time.split(":")
                    var currETime = shiftLookup[j].end_time.split(":")

                    console.log(currSTime,currETime,changedSTime,changedETime)

                    if(parseInt(currSTime[0]) < parseInt(changedETime[0]) &&  parseInt(changedETime[0]) < parseInt(currETime[0])){
                        // if(parseInt(currSTime[1]) <= parseInt(changedSTime[1]) ||  parseInt(changedETime[1]) <= parseInt(currETime[1])){
                            form.shifts[i].end_time = shiftLookup[i].end_time
                            alert("Two shifts cannot overlap 4")
                            break
                        // }
                    }
                    else if(parseInt(currETime[0]) == parseInt(changedETime[0]) && parseInt(currETime[1]) <= parseInt(changedETime[1])){
                        form.shifts[i].end_time = shiftLookup[i].end_time
                        alert("Two shifts cannot overlap 5")
                        break
                    }
                    else if(parseInt(currSTime[0]) == parseInt(changedETime[0]) && parseInt(currSTime[1]) <= parseInt(changedETime[1])){
                        form.shifts[i].end_time = shiftLookup[i].end_time
                        alert("Two shifts cannot overlap 6")
                        break
                    }else if(changedETime[0] <= changedSTime[0] && changedETime[1] <= changedSTime[1]){
                        form.shifts[i].start_time = shiftLookup[i].start_time
                        alert("Shift start time and end time cannot overlap 2")
                        break
                    }else{
                        shiftLookup[i].end_time = form.shifts[i].end_time
                    }
                }
                break
            }

        }
    }

    async function deleteLastShift(){
        if(shiftCount.value > 1) {
            shiftCount.value -= 1
            var delShift = form.shifts.pop()
            shiftLookup.pop()

            if(delShift.id != null){
                console.log("api call to delete shift")
                await axios.post('delete_shift',{ "id" : delShift.id })
                .then(response => (
                        console.log("response in delete_shift",response)
                    )
                )
            }
        }
    }


    function addNewShift() {
        if(shiftCount.value < 5) {
            shiftCount.value += 1
            shiftLookup.push({ id: null, shift_name: "Shift " +  parseInt(form.shifts.length + 1), start_time: "", end_time: ""})
            form.shifts.push({ id: null, shift_name: "Shift " +  parseInt(form.shifts.length + 1), start_time: "", end_time: ""})
            
        }
    }

    async function getSystemSettings(){

        var clientId = store.getters.stateUser.associated_client

        if(clientId != null){
            await axios.post('get_system_settings',{ "client_id" : clientId })
            .then(response => (
                    console.log("response in get_system_settings",response),
                    timeZones.value = response.data.timezones,
                    form.operators = response.data.operators,
                    form.selected_timezone = response.data.selected_timezone,
                    form.current_timezone = response.data.current_timezone,
                    form.running_color = response.data.running_color,
                    form.stopped_color = response.data.stopped_color,
                    form.shifts = response.data.shifts,
                    shiftCount.value = form.shifts.length,
                    form.ss_id = response.data.ss_id,
                    shiftLookup = structuredClone(response.data.shifts)
                    
                )
            )
            loaderShow.value = false
        }
        else{
            router.push({ name: 'Home'})
        }
        
    }


    async function updateSystemSettings(){

        var clientId = store.getters.stateUser.associated_client
        if(clientId != null){

            try{

                await axios.post('update_system_settings',form)
                .then(response => (
                        console.log("response in get_system_settings",response),
                        form.shifts = response.data,
                        getSystemSettings(),
                        toast("System Settings Updated Successfully", {
                            autoClose: 3000,
                            theme: "colored",
                            type: "success",
                            position: "bottom-left",
                            transition: "slide",
                            dangerouslyHTMLString: true
                        })
                    )
                )
            }catch(error){
                
                toast(error.response.data.detail, {
                    autoClose: 3000,
                    theme: "colored",
                    type: "error",
                    position: "bottom-left",
                    transition: "slide",
                    dangerouslyHTMLString: true
                })
            }


            
        }
        else{
            router.push({ name: 'Home'})
        }

    }

    // function timeChanged(data, timepicker){
    //     console.log("time",data,timepicker)
    // }


    onMounted(() =>{
        getSystemSettings()
    })
    
</script>

<template>
<LoaderComponent :show="loaderShow" />
<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <div class="card settings-card">
            <div class="form-wrapper">
                
                <BForm @submit="onSubmit" @reset="onReset">
                <div class="container-fluid">
                    <!-- <BButton style="margin-bottom: 1rem;" variant="primary" @click="addNewShift()" class="md"><font-awesome-icon :icon="['fas', 'plus']" /> &nbsp;Add New Shift</BButton> -->
                    <div class="row">
                        <h4>Number of Shifts:</h4>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <BInputGroup inline>
                                <template #prepend>
                                    <BButton variant="danger" @click="deleteLastShift"><font-awesome-icon :icon="['fas', 'minus']" /></BButton>
                                </template>
                                <BFormInput style="text-align: center;"  v-model="shiftCount" />
                                <template #append>
                                    <BButton variant="success" @click="addNewShift"><font-awesome-icon :icon="['fas', 'plus']" /></BButton>
                                </template>
                            </BInputGroup>
                        </div>
                    </div>
                    

                    <hr>

                    <div class="row form-row " v-for="shift in form.shifts" :key="shift.id">

                            <h4>{{ shift.shift_name }}</h4>
                        
                            <div class="col-lg-6 indv-input">
                                <BFormGroup id="input-group-1" label="Shift Start Time"  label-for="input-1" >
                                    <!-- <mdtimepicker :timeChanged="timeChanged" class="form-control"  :value="shift.start_time" /> -->
                                    <BFormInput @change="checkShiftStartInput(shift.shift_name)" v-model="shift.start_time" :value="shift.start_time"  type="time" size="md"  required />
                                </BFormGroup>
                                
                            </div>

                            <div class="col-lg-6 indv-input">
                                <BFormGroup id="input-group-1" label="Shift End Time"  label-for="input-1" >
                                    <!-- <mdtimepicker :timeChanged="timeChanged" class="form-control"  :value="shift.end_time"  /> -->
                                    <BFormInput @change="checkShiftEndInput(shift.shift_name)" v-model="shift.end_time" :value="shift.end_time"  type="time" size="md"  required />
                                </BFormGroup>
                                
                            </div>

                            <hr class="shift-hr">
                        
                    </div>


                    <div class="row form-row">
                        <div class="col-lg-6 indv-input">
                        <h5>TimeZone  Settings</h5>

                        <select v-model="form.selected_timezone" class="form-select form-select-sm" id="timezone-select" aria-label="length-select">
                            <option :selected="form.selected_timezone == null" value="null"> Select Timezone </option>
                            <option v-for="opt in timeZones" :key="opt" :value="opt" :selected="opt == form.selected_timezone" >{{ opt }}</option>
                        </select>
                    </div>
                    </div>

                    <br/>
                    <div class="row form-row">
                        <div class="col-lg-6 indv-input">
                            <p style="font-weight: bold;">Current Timezone: {{ form.current_timezone }}</p>
                        </div>
                    </div>

                    <hr>
                    
                    <div class="row form-row">
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Running Color"  label-for="input-1" >
                                <input type='color' v-model="form.running_color" class="form-control form-control-sm"  id='color-picker-running' />
                            </BFormGroup>
                            
                        </div>
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Stopped Color"  label-for="input-1" >
                                <input type='color' v-model="form.stopped_color" class="form-control form-control-sm"  id='color-picker-stopped' />
                            </BFormGroup>
                            
                        </div>
                    </div>
                    
                    <br>

                    <div class="row form-row">
                        <div class="col-lg-6 indv-input">
                            <h6 class="enable-operator-elm">Enable Operators:  </h6>
                            <span class="enable-operator-elm"><BFormCheckbox v-model="form.operators" :checked="form.operators" size="lg" switch></BFormCheckbox></span>
                        </div>
                    </div>

                    <div class="sumbit-btn-wrapper">
                        <BButton class="form-submit-btn" type="submit" variant="primary">Submit</BButton>
                    </div>
                    </div>
                    
                </BForm>
            </div>
            

        </div>
        
      </div>
    </BCol>
  </BRow>
  
</BContainer>
</template>
