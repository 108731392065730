<script setup>
import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import DataTableComponent from '../components/DataTableComponent.vue'
    import ModalComponent from '@/components/ModalComponent.vue';
    import LoaderComponent from '../components/LoaderComponent.vue'
    
    import 'bootstrap';
    import $ from 'jquery'
    import { ref,onMounted } from 'vue'
    import axios from 'axios';
    import { useStore } from 'vuex'
    import router from '@/router';
    
    const store = useStore()
    
    // var part_keys = ref(Object())
    var flexDieData = ref([])
    var magCylData = ref([])
    var solidDieData = ref([])
    var adjustmentData = ref([])
    var loaderShow = ref(true)
    // var output = ref("")
    var data = ref("")
    var showDies = ref(false)
    var showMags = ref(false)
    var showSolidDies = ref(false)

    

    function editIcon(data, type, row) {
        if (type === 'display') {
            return '<div class="edit-rev-elm" contenteditable="false">'+ data + '</div>' + '<button class="btn edit-rev-count edit-rev-elm"> <i class="fa fa-pencil"/></button>';
        }
        return data;
    }

    

    async function getDeviceStatusData() {
      var output = ''
      var clientId = store.getters.stateUser.associated_client
      if(clientId != null){
        await axios.post('get_device_status', { "id": clientId})
        .then(response => (
            console.log("response in device status",response),
            output = response.data
          )
        )
        return output
      }
      else{
        router.push({ name: 'Home'})
      }
    }


    async function processData(){
      data = await getDeviceStatusData()

      var part_keys = Object.keys(data)
      for(var i=0;i<part_keys.length;i++){
        if(part_keys[i]=="Flex Die"){
          showDies.value = true
          flexDieData = data[part_keys[i]]
        }
        if(part_keys[i]=="Mag Cyl"){
          showMags.value = true
          magCylData = data[part_keys[i]]
        }
        if(part_keys[i]=="Solid Die"){
          showSolidDies.value = true
          solidDieData = data[part_keys[i]]
        }
       
      }

      loaderShow.value = false

    }

    async function getAdjustmentsData(device_serial,adjustment_type) {
      var output = ''
      
      var userData = store.getters.stateUser
      console.log("serial in device is",device_serial,userData.id,adjustment_type)
      if(userData != null){
        await axios.post('get_adjustments', { "user_id": userData.id, "adjustment_id": device_serial, "adjustment_type": adjustment_type, "client_id": userData.associated_client})
        .then(response => (
            console.log("response in getAdjustmentsData",response),
            output = response.data
          )
        )
        return output
      }
      else{
        router.push({ name: 'Home'})
      }
    }



    async function editRevs(device_serial,revs_count) {
      var output = ''
      
      var userData = store.getters.stateUser
      if(userData != null){
        await axios.post('edit_revs', { "serial": device_serial, "revs_count": revs_count})
        .then(response => (
            console.log("response in edit_revs",response),
            output = response.data
          )
        )
        return output
      }
      else{
        router.push({ name: 'Home'})
      }
    }



    
    
    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: true,
        "drawCallback": function( settings ) {
              var api = this.api();
      
              // Output the data for the visible rows to the browser's console
              console.log( api.rows( {page:'current'} ).data() );
          },
          createdRow: function (row, data, index) {
            console.log("row",row,data,index)
            $(row).attr("id", data[0] + "__" +data[3])
          
          },
          // "createdRow": function (row, data, index) {
          //   $('td:eq(1)', row).html( data[1]+ '<button class="btn edit-rev-count"><i class="fa-solid fa-pen"></i></button>' );
          // },
    }

    const adjColumns = [
      { render : function ( data ) {
          return '<span class="adj-side-highlight">'+ data +'</span>'
        }
      },
      { render : function ( data ) {
          return '<span class="adj-side-highlight">'+ data +'</span>'
        }
      },
      {},
      {},
      {},
      {},
    ]
    

    const columns = [
      { },
      { render: editIcon },
      { render : function ( data ) {
        return data > 0 ? '<button data-target="#modal-center" data-toggle="modal" @click="showModal"  class="btn data-table-btn data-table-adj-btn device-adjustments">'+ data +'</button>'  : '<button disabled class="btn data-table-btn data-table-adj-btn device-adjustments">'+ data +'</button>'
      } },
    ]

    const adjustmentOptions = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: false,
        "drawCallback": function( settings ) {
              var api = this.api();
      
              // Output the data for the visible rows to the browser's console
              console.log( api.rows( {page:'current'} ).data() );
          },
          
          // "createdRow": function (row, data, index) {
          //   $('td:eq(1)', row).html( data[1]+ '<button class="btn edit-rev-count"><i class="fa-solid fa-pen"></i></button>' );
          // },
    }


    $(document).on('click', '.edit-rev-count', function(){ 
      // $(this).parent().children().remove('button')
      
      $(this).parent().children("div").attr('contenteditable','true');
      $(this).parent().children("div").focus()
      $(this).replaceWith('<button class="btn btn-success submit-rev-count"> <i class="fa-solid fa-check"></i></button>')
    });

    $(document).on('click', '.submit-rev-count',async function(e){ 
      // $(this).parent().children().remove('button')
      var serial = $(this).parent().parent().attr("id")

      const splittedData = serial.split("__")
      
      var edited_revs = $(this).parent().children("div").text()

      var updated_revs_data = await editRevs(splittedData[0],edited_revs)

      console.log("updated_revs",updated_revs_data)

      $(this).parent().children("div").text(updated_revs_data.updated_rev_count)

      $(this).parent().children("div").attr('contenteditable','false');
      $(this).replaceWith('<button class="btn edit-rev-count edit-rev-elm"> <i class="fa fa-pencil"/></button>')

      e.stopImmediatePropagation();
     
    });
    
    $(document).on('click', '.device-adjustments', async function(e){ 
      console.log("dev serial in adjustment",$(this).parent().parent().attr("id"))
      var serial = $(this).parent().parent().attr("id")
      const splittedData = serial.split("__")
      
      var adjData = await getAdjustmentsData(splittedData[0],splittedData[1])

      console.log("adjData is ",adjData)

      adjustmentData.value = adjData
      
      $('#modal-center').addClass('modal fade show')
      $('#modal-center').css('display','block')
      $('#modal-center').css('z-index','1055')
      $('body').css('overflow','hidden')
      $('body').addClass('modal-open')
      $('.b-overlay-wrap').attr('aria-busy','true')
      $('.b-overlay-wrap').html('<div class="b-overlay position-fixed" style="inset: 0px; z-index: 10;"><div class="position-absolute bg-dark" style="inset: 0px; opacity: 0.85;"></div><div class="position-absolute" style="top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);"><!----></div></div>')
      // $("#modal-center").modal('show');
      e.stopImmediatePropagation();
    });

    $(document).on('click', '.b-overlay, .btn-close, .modal-close', function(){ 
      $('body').css('overflow','')
      $('body').removeClass('modal-open')
      $('#modal-center').removeClass('show')
      $('#modal-center').css('display','none')
      $('#modal-center').css('z-index','1055')
      $('.b-overlay-wrap').removeAttr('aria-busy','true')
      $('.b-overlay-wrap').html('')
      // $("#modal-center").modal('show');
    });


    // const adjustmentData =[
    //   ['-8.0', 'Drive',	'11/28/2023', '17:00:57',	'Flex',	'DIE1128000000///////////' ],
      
    // ]

    onMounted(() => {
      processData()
    })
</script>

<template>
<LoaderComponent :show="loaderShow" />
<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <BTabs>
            <BTab titleLinkClass="DS_tabs" v-if="showDies">
              <template #title>
                <span>Flex Die</span>
              </template>
                <DataTableComponent :data="flexDieData" :columns="columns" :options="options">
                    <template #table_header>
                    <th>Serial Number</th>
                    <th>Revolutions</th>
                    <th>Adjustments</th>
                    </template>
                </DataTableComponent>
            </BTab>
            <BTab  titleLinkClass="DS_tabs" v-if="showMags">
              <template #title>
                <span>Mag Cyl.</span>
              </template>
                <DataTableComponent :data="magCylData" :columns="columns" :options="options">
                    <template #table_header>
                    <th>Serial Number</th>
                    <th>Total Use (Revolutions)</th>
                    <th>Adjustments Made</th>
                    </template>
                </DataTableComponent>
            </BTab>
            <BTab  titleLinkClass="DS_tabs" v-if="showSolidDies">
              <template #title>
                <span>Solid Die</span>
              </template>
                <DataTableComponent :data="solidDieData" :columns="columns" :options="options">
                    <template #table_header>
                    <th>Serial Number</th>
                    <th>Total Use (Revolutions)</th>
                    <th>Adjustments Made</th>
                    </template>
                </DataTableComponent>
            </BTab>
        </BTabs>
        <br/>
        <!-- <BButton v-b-modal.modal-center>Launch centered modal</BButton> -->
      </div>

      
    </BCol>
  </BRow>


  <ModalComponent modalTitle="Adjustment Info">
    <template #modal-body>
      <DataTableComponent :data="adjustmentData" :columns="adjColumns" :options="adjustmentOptions">
          <template #table_header>
          <th>Drive Side Adjustments	</th>
          <th>Operator Side Adjustments</th>
          <th>When Adjusted	</th>
          <th>Mag Cyl Serial	</th>
          <th>Die Serial</th>
          <th>Die Type	</th>
          
          
          </template>
      </DataTableComponent>
    </template>
    
  </ModalComponent>
</BContainer>
</template>

<script>

    export default {
      methods: {
        showModal() {
          this.$refs['modal-center'].show()
        }
      },
      name: 'DeviceStatusView',
      components: {
          DataTableComponent,
          SidebarComponent,
          NavbarComponent,
          ModalComponent
          // HelloWorld
      },
      mounted () {
          
        },
        data() {
          return {
            

        };
      }
    }
</script>