import axios from 'axios';

const state = {
  user: null,
};

const getters = {
  isAuthenticated: state => !!state.user,
  stateUser: state => state.user,
};

const actions = {
  async register({dispatch}, form) {
    await axios.post('register', form);
    let UserForm = new FormData();
    UserForm.append('username', form.username);
    UserForm.append('password', form.password);
    await dispatch('logIn', UserForm);
  },

  async logIn({dispatch}, user) {
    
    try{
      var currentURL = null
      for (let [key, value] of user) {
        if(key == "baseUrl"){
          currentURL = value + ":5000/"
        }
      }
      
      user.delete("baseUrl")
      if(currentURL != null){
        axios.defaults.baseURL = currentURL 
      }
      
      var responseData = await axios.post('login', user).then(response => {
          return response
        }
      );  
      if(responseData.status == 200){
        console.log("responsedata is ",responseData)
        await dispatch('viewMe',currentURL);
      }
      
    }catch (error){
      console.log("error response",error)
      return error.response
    }
    

    
    return responseData
  },
  
  async autoLogIn({dispatch}, queryObj) {
    
    try{

      axios.defaults.baseURL = queryObj.currURL + ":5000/" 

      var responseData = await axios.post('auto_login_user', { "id": queryObj.userId}).then(response => {
          return response
        }
      );  
      if(responseData.status == 200){
        console.log("responsedata in autologin",responseData)
        await dispatch('viewMe',queryObj.currURL + ":5000/" );
      }
      
    }catch (error){
      console.log("error response",error)
      return error.response
    }
    

    
    return responseData
  },


  async adminLogin({dispatch}, user) {
    
    try{
      var currentURL = null
      for (let [key, value] of user) {
        if(key == "baseUrl"){
          currentURL = value + ":5000/"
        }
      }
      
      user.delete("baseUrl")
      if(currentURL != null){
        axios.defaults.baseURL = currentURL 
      }


      var responseData = await axios.post('admin_login', user).then(response => {
          return response
        }
      );  
      if(responseData.status == 200){
        console.log("responsedata in adminlogin  is ",responseData)
        await dispatch('adminViewMe',currentURL)
      }
      
    }catch (error){
      console.log("error response",error)
      return error.response
    }
    

    
    return responseData
  },
  async viewMe({commit},baseurl) {

    console.log("url in viewme",baseurl)
    axios.defaults.baseURL = baseurl
    let {data} = await axios.get('users/whoami');
    // console.log("user data in viewme is",data)
    await commit('setUser', data);
  },
  async adminViewMe({commit},baseurl) {
    axios.defaults.baseURL = baseurl
    let {data} = await axios.get('admin_users/whoami');
    // console.log("user data in viewme is",data)
    await commit('setAdminUser', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteUser({}, id) {
    await axios.delete(`user/${id}`);
  },
  async logOut({commit}) {
    let user = null;
    commit('logout', user);
  }
};

const mutations = {
  setUser(state, username) {
    state.user = username;
    state.userId = username.id
    state.clientId = username.associated_client
    console.log("state in", state)
  },
  setAdminUser(state, username) {
    state.user = username;
    console.log("state in setAdminUser", state)
  },
  logout(state, user){
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};