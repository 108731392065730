<script setup>

import { defineProps } from 'vue'

const props = defineProps(["show"])

</script>


<template>
    <BOverlay :show="props.show" no-wrap="true" fixed="true" opacity="0.8" rounded="sm">
        <template #overlay>
            <div class="text-center">
                <p id="cancel-label">Please wait...</p>
                <img class="header__logo" src="../assets/images/logo-inverse.png" alt = "Maxcess Logo">
            </div>
        </template>
    </BOverlay>
</template>
