<script setup>

    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import LoaderComponent from '../components/LoaderComponent.vue'

    import { ref,onMounted } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex'
    import { toast } from "vue3-toastify";
    import router from '@/router';

    const store = useStore()

    var maintenanceSettings = ref(null)
    var loaderShow = ref(true)


    const onSubmit = async (event) => {
        event.preventDefault()
        loaderShow.value = true
        // alert(JSON.stringify(form))
        console.log(maintenanceSettings.value)
        var clientId = store.getters.stateUser.associated_client
        if(clientId != null){

            try{
                await axios.post('update_maintenance_settings', {"settings": maintenanceSettings.value, "client_id": clientId})
                .then(response => (
                    console.log("response in update_maintenance_settings",response),
                    toast(response.data, {
                        autoClose: 3000,
                        theme: "colored",
                        type: "success",
                        position: "bottom-left",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    })
                )
                )

                loaderShow.value = false
            }catch(error){
                loaderShow.value = false
                toast(error.response.data.detail, {
                    autoClose: 3000,
                    theme: "colored",
                    type: "error",
                    position: "bottom-left",
                    transition: "slide",
                    dangerouslyHTMLString: true
                })
            }

        }
        else{
            router.push({ name: 'Home'})
        }
    }
    

    async function getMaintenanceSettings(){
        var clientId = store.getters.stateUser.associated_client
        if(clientId != null){
            await axios.post('get_maintenance_settings', { "client_id": clientId})
            .then(response => (
                console.log("response in get_maintenance_settings",response),
                maintenanceSettings.value = response.data
            )
            )

            loaderShow.value = false
        }
        else{
            router.push({ name: 'Home'})
        }
    }


    onMounted(() => {
        getMaintenanceSettings()
    })
    

    
</script>

<template>
<LoaderComponent :show="loaderShow" />

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <div class="card settings-card">
            <div class="form-wrapper">
                
                <BForm @submit="onSubmit">
                <div class="container-fluid">

                    <div v-for="ms in maintenanceSettings" :key="ms.mt_id">
                        <div class="row form-row">
                            

                            <h5>{{ ms.device_type.name }} - {{ ms.name }}</h5>

                            <div class="col-lg-6 indv-input">
                                <BFormGroup id="input-group-1" label="Maintainance Interval"  >
                                <BInputGroup size="sm" :append="ms.within_type"><BFormInput  type="text" v-model="ms.recommended_within" size="sm" placeholder="" required /></BInputGroup>
                                </BFormGroup>
                                
                            </div>
                            <div class="col-lg-6 indv-input">
                                <BFormGroup id="input-group-1" label="Remind daily if overdue?"  >
                                    <select v-model="ms.alert_daily" class="form-select form-select-sm" aria-label="length-select">
                                        <option :selected = "ms.alert_daily == true" value="true">Yes</option>
                                        <option :selected = "ms.alert_daily == false" value="false">No</option>
                                    </select>
                                </BFormGroup>
                            </div>
                            
                        </div>
                        
                        <hr>
                    </div>

                    <div class="sumbit-btn-wrapper">
                        <BButton class="form-submit-btn" @click="showDismissibleAlert = !showDismissibleAlert" type="submit" variant="primary">Submit</BButton>
                    </div>
                </div>
                    
                </BForm>
            </div>
            

        </div>
        
      </div>
    </BCol>
  </BRow>
  
</BContainer>
</template>

