<script setup>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import SpeedometerComponent from '@/components/SpeedometerComponent.vue';
    import LoaderComponent from '../components/LoaderComponent.vue'
    import NoDataComponent from '@/components/NoDataComponent.vue';
    import { VuePlotly } from 'vue3-plotly'
    // import VueGauge from 'vue-gauge';
    // import MainComponent from '../components/MainComponent.vue'

    import { ref, reactive, onBeforeUnmount, onBeforeMount, defineProps } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router'


    var devices = ref([])
    var loaderShow = ref(true)
    var noData = ref(true)

    const props = defineProps(["query","currURL"])

    // var data = ref([{
    //   values: [19, 26, 55],
    //   labels: ['Running - 00.15 hrs', 'Jog - 1:34 hrs', 'Setup - 2:45 hrs'],
    //   type: 'pie',
    //   textinfo:'none',
    //   marker: {
    //   colors: [
    //     '#41d5f0',
    //     '#41a7f0',
    //     '#2b3889'
    //   ]
    // },
    // }]);

    var layout = reactive({
      height: 150,
      // width: 300,
      margin: {
        t: 0,
        b: 0,
        l: 0,
        r: 0
      },
      autosize: true,
      showlegend: true,
      legend: {
        x: 1,
        y: 0.5,
        font: {
          size: 17,
        }
      },
      
    });

    var config = {responsive: true}
    

  //   var drivePressureData = [
  //   {
  //     type: "indicator",
  //     mode: "gauge+number",
  //     value: 450,
  //     tickcolor: 'red',
  //     number: {suffix: " psi", 'font': {'size': 30, color: "black"}},
  //     // title: { text: "Speed (in/sec)", font: { size: 20 } },
  //     delta: { reference: 450, increasing: { color: "green" } },
  //     gauge: {
  //       axis: { range: [null, 500], tickwidth: 1, tickcolor: "darkblue" },
  //       bar: { color: "#f0efeb",thickness: 0.3, },
  //       bgcolor: "white",
  //       borderwidth: 2,
  //       bordercolor: "#e6e9ed",
  //       steps: [
  //         { range: [0, 166],   color: "#c2c1be" },
  //         { range: [166, 332], color: "#a3a29e" },
  //         { range: [332, 490], color: "#918f8a" }
  //       ],
  //       threshold: {
  //         line: { color: "red", width: 4 },
  //         thickness: 0.75,
  //         value: 490
  //       }
  //     }
  //   }
  // ];

  var drivePressureLayout = {
    height: 120,
    // width: 180,
    margin: { t: 20, r: 15, l: 15, b: 5 },
    font: { color: "darkblue", family: "Arial" },
    autosize: true,
    annotations: [
    {
      x: 0.50,
      y: 0.50,
      text: 'Drive',
      showarrow: false,
      arrowhead: 0,
      font: {
        size: 20,
        color: '#61615e'
      }
    },
  ]
  };

  // var operatorPressureData = [
  //   {
  //     type: "indicator",
  //     mode: "gauge+number",
  //     value: 250,
  //     number: {suffix: " psi", 'font': {'size': 30, color: "black"}},
  //     // title: { text: "Speed (in/sec)", font: { size: 20 } },
  //     delta: { reference: 450, increasing: { color: "green" } },
  //     gauge: {
  //       axis: { range: [null, 500], tickwidth: 1, tickcolor: "darkblue" },
  //       bar: { color: "#f0efeb",thickness: 0.3, },
  //       bgcolor: "white",
  //       borderwidth: 2,
  //       bordercolor: "#e6e9ed",
  //       steps: [
  //         { range: [0, 166],   color: "#c2c1be" },
  //         { range: [166, 332], color: "#a3a29e" },
  //         { range: [332, 490], color: "#918f8a" }
  //       ],
  //       threshold: {
  //         line: { color: "red", width: 4 },
  //         thickness: 0.75,
  //         value: 490
  //       }
  //     }
  //   }
  // ];

  var operatorPressureLayout = {
    height: 120,
    // width: 180,
    margin: { t: 20, r: 20, l: 15, b: 5 },
    font: { color: "darkblue", family: "Arial" },
    autosize: true,
    annotations: [
    {
      x: 0.50,
      y: 0.50,
      text: 'Operator',
      showarrow: false,
      arrowhead: 0,
      font: {
        size: 20,
        color: '#61615e'
      }
    },
  ]
  };

  // var gaugeOptions = {
  //   'chartWidth': 250,
  //   'needleValue':65,
  //   'arcDelimiters':[10,36,78],
  //   'arcLabels':[200,400,600],
  //   'hasNeedle':true,
  //   'rangeLabel': ['0','720'],
  //   'centralLabel': "550"
  // }


  // var downtimeData = ref([{
  //     values: [19, 26, 55],
  //     labels: ['Idle Time - 00.15 hrs', 'OI-Wifi - 1:34 hrs', 'Machine - 2:45 hrs'],
  //     type: 'pie',
  //     hole: .4,
  //     textinfo: "label",
  //     textposition: 'inside',
  //     insidetextorientation: "inside",
      
  //   }]);

  //   var downtimeLayout = ref({
  //     height: 200,
  //     title: { text: "Downtimes", font: { size: 17 } },
  //     width: 200,
  //     margin: {
  //       t: 30,
  //       b: 0,
  //       l: 0,
  //       r: 0
  //     },
  //     autosize: true,
  //     showlegend: false,
  //     legend: {
  //       x: 1,
  //       y: 0.5
  //     },
  //     uniformtext: {
  //     mode: "show",
  //     minsize: 10
  //   }
      
  //   });

   async function getDashboardData(){
      var responseData = null
      if(store.getters.stateUser != null){
        await axios.post('get_dashboard_data', store.getters.stateUser)
          .then(response => (
              console.log("response in get_dashboard_data",response),
              responseData = response
          )
        )

        if(responseData.status == 200){
          devices.value = responseData.data
          noData.value = false
        }else if(responseData.status == 204){
            noData.value = true
          }

        loaderShow.value = false
      }
      else{
        router.push({ name: 'Home'})
      }
   }

   var polling = ref(null)

   function pollData () {
		polling.value = setInterval(() => {
			getDashboardData()
		}, 15000)
	}


  // if(props.userId === undefined || props.userId == ""){
  //   getDashboardData()
  //   console.log("here in if ")

  // }else{
  //   console.log("here in check")
  //   // await store.dispatch('autoLogIn',props.userId);
  //   // await store.dispatch('viewMe')
  // }

  // getDashboardData()
  pollData()
  

  onBeforeMount(async () => {
    console.log("props",props.query,props.currURL,noData.value)
    if(props.query === undefined || props.query == ""){
      await getDashboardData()
      console.log("here in if ")

    }else{
      console.log("here in check")
      await store.dispatch('autoLogIn',{ "userId":props.query, "currURL": props.currURL});
      await store.dispatch('viewMe',props.currURL + ":5000/")
      await getDashboardData()
    }
  })

  onBeforeUnmount(() => {
    clearInterval(polling.value)
  })
  
</script>

<template>
<LoaderComponent :show="loaderShow" />
<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" @logout="logout"/>
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />
      <div class="main-expand" id="main" v-if="noData"><NoDataComponent message="No data to display !!" /></div>
      <div class="main-expand" id="main" v-else>

        <div class="row">
          <div class="col-lg-6 mb-3" v-for="dev in devices" :key="dev.device_name" :id="dev.device_name">
            <BCard :header-tag="dev.device_status ? 'dashboard-card-header' : 'dashboard-card-header-stopped'" footer-tag="footer">
              <template #header >
                
                <BBadge v-if="dev.maintenance_priority != 3" class="device-badge" dot-indicator style="padding: 0 !important;background-color: transparent !important;border: none !important; z-index: 2;">
                  <span v-if="dev.maintenance_priority == 1" :id="dev.device_name+'-'+dev.maintenance_priority" class="fa-stack fa-1x">
                      <font-awesome-icon :icon="['fas', 'circle']" class="fa-stack-1x fa-stack-circle" beat style="color: #ec3722;" />
                      <font-awesome-icon :icon="['fas', 'screwdriver-wrench']" class="fa-stack-1x fa-stack-wrench" beat style="color: white;" />
                  </span>
                  <span v-if="dev.maintenance_priority == 2" :id="dev.device_name+'-'+dev.maintenance_priority" class="fa-stack fa-1x">
                      <font-awesome-icon :icon="['fas', 'circle']" class="fa-stack-1x fa-stack-circle" beat style="color: #ffef08;" />
                      <font-awesome-icon :icon="['fas', 'screwdriver-wrench']" class="fa-stack-1x fa-stack-wrench" beat style="color: black;" />
                  </span>
                </BBadge>

                <BPopover :target="dev.device_name+'-'+dev.maintenance_priority" triggers="hover" placement="bottom">
                  <template #title></template>
                  <h6 v-for="mt in dev.maintenance" :key="mt.maintenance_desc" >{{ mt.maintenance_desc }}</h6>
                </BPopover>

                <div class="row">
                  <div class="col-lg-1">
                    <font-awesome-icon :id="`popover-info-${dev.device_id}`" :icon="['fas', 'circle-info']" style="background-color: white; border-radius: 8px;" size="lg"/>
                    <BPopover :target="`popover-info-${dev.device_id}`" triggers="hover" placement="bottom">
                      <p>Operator Name: {{ dev.operator_name }}</p>
                      <p>{{ dev.die_type }}: {{ dev.die_id }}</p>
                      <p>Mag Cyl: {{ dev.mag_id }}</p>
                      <p>Material ID : {{ dev.material_id }}</p>
                      <p>Job Description: {{ dev.job_description }}</p>
                    </BPopover>
                  </div>
                  <div class="col-lg-10 d-flex justify-content-center">
                    <h5 class="dashboard-device-name" :style="[dev.device_status ? {'color': 'black','text-shadow': 'white 0px 0px 2px'} : {'color': 'white','text-shadow': 'black 0px 0px 2px'}]">
                      {{ dev.device_name }} ( Job : {{ dev.job_name }}) &nbsp;
                    </h5>
                    <!-- <font-awesome-icon id="job-info" :icon="['fas', 'circle-info']" style="background-color: white; border-radius: 8px;" size="lg"/>
                      <BPopover target="job-info" triggers="hover" placement="bottom">
                        <p>Job Length: 250 in</p>
                        <p>Job Description: Label Cutter</p>
                      </BPopover> -->
                    
                  </div>
                  <div class="col-lg-1">
                    <div class="ribbon" :class="[dev.device_status ? 'green' : 'red']">
                      <span v-if="dev.device_status">Running</span>
                      <span v-else>Stopped</span>
                    </div>
                    <!-- <BBadge variant="success" class="status-badge" pill>Running</BBadge> -->
                  </div>
                </div>
                
                  
                <div class="row text-center">
                  <div class="col-lg-12"><h5><BBadge variant="light" pill>Job Length: {{ dev.job_length }}</BBadge></h5></div>
                  <!-- <div class="col-lg-12"><h5><BBadge variant="light" pill> Job Description: {{ dev.job_description }}</BBadge></h5></div> -->
                </div>
                  
                  
              </template>
              
              <BAccordion flush free class="dashboard-accordion">
                <BAccordionItem title="Job Metrics" :visible="true">
                  <div class="job-metrics-chart text-center">
                    <!-- <h6>Job Metrics</h6> -->
                    <div class="plotly-graph-wrapper">
                      <VuePlotly :data="dev.job_metrics_chart" :layout="layout" :config="config" :display-mode-bar="false"></VuePlotly>
                    </div>
                  </div>
                </BAccordionItem>
              

              <!-- <hr> -->
              <BAccordionItem title="Clearance" :visible="true">
                <div class="row text-center">

                  <!-- <h6>Clearance</h6> -->

                  <div class="col-lg-6" style="margin: auto;">
                    <div class="container-fluid text-center">
    
                      <div class="range-slider">
                        <span id="rs-bullet" class="rs-label">{{ dev.drive_clearance }}</span>
                        <input disabled id="rs-range-line"  class="rs-range" type="range" :value="dev.drive_clearance" min="-45" max="45">
                        
                      </div>
                      
                      <div class="box-minmax">
                        <span>-45</span><span>+45</span>
                      </div>

                      <h5 class="side-labels"><BBadge style="background-color: #2b3889 !important;" variant="secondary" pill>Drive</BBadge></h5>
                      
                    </div>
                  </div>


                  <div class="col-lg-6">
                    <div class="container-fluid text-center">
    
                      <div class="range-slider">
                        <span id="rs-bullet" class="rs-label">{{ dev.operator_clearance }}</span>
                        <input disabled id="rs-range-line"  class="rs-range" type="range" :value="dev.operator_clearance" min="-45" max="45">
                        
                      </div>
                      
                      <div class="box-minmax">
                        <span>-45</span><span>+45</span>
                      </div>

                      <h5 class="side-labels"><BBadge style="background-color: #2b3889 !important;" variant="secondary" pill>Operator</BBadge></h5>
                      
                    </div>
                  </div>
                </div>
              </BAccordionItem>

              <!-- <hr> -->
              <BAccordionItem title="Pressures (psi)" :visible="true">
                <div class="row text-center">
                  <!-- <h6>Pressures (psi)</h6> -->
                  <div class="col-lg-6"><div class="plotly-graph-wrapper"><VuePlotly :data="dev.left_bearer" :layout="drivePressureLayout" :config="config" :display-mode-bar="false"></VuePlotly></div></div>
                  <div class="col-lg-6"><div class="plotly-graph-wrapper"><VuePlotly :data="dev.right_bearer" :layout="operatorPressureLayout" :config="config" :display-mode-bar="false"></VuePlotly></div></div>
                </div>
              </BAccordionItem>

              <!-- <hr> -->
              <BAccordionItem title="Speed / Downtimes" :visible="true">
                <div class="row text-center">
                  <div class="col-lg-6">
                    <h6>Speed ({{ dev.speed_unit }})</h6>
                    <SpeedometerComponent :speedValue="dev.speed" :maxSpeed="dev.max_speed"/>
                    <!-- <vue-gauge :refid="'type-unique-id'" :options=gaugeOptions></vue-gauge> -->
                  </div>
                  <div class="col-lg-6">
                    <!-- <div class="col-lg-6"><VuePlotly :data="downtimeData" :layout="downtimeLayout" :config="config" :display-mode-bar="false"></VuePlotly></div> -->
                    <h6>Downtimes</h6>

                    <BBadge class="downtime-badge" variant="danger">Job Downtime :  {{ dev.job_downtime }}</BBadge><br>
                    <BBadge class="downtime-badge" variant="" style="background-color: #f6cb5d !important; color: #0d0d0d;">Idle Downtime: {{ dev.idle_downtime }}</BBadge><br>
                    <BBadge class="downtime-badge" variant="secondary">Internet Downtime : {{ dev.internet_downtime }}</BBadge>

                    <hr>

                    <BBadge variant="light" pill class="reported-badge"><font-awesome-icon :icon="['fas', 'clock']" style="color: #0d0d0d;" /> Last Reported: {{ dev.last_reported }}</BBadge>
                  </div>
                </div>
              </BAccordionItem>

            </BAccordion>
              
              
              <template #footer>
                
              </template>
            </BCard>
          </div>


          
        </div>
        
      </div>
    </BCol>
  </BRow>
  
</BContainer>

  
</template>




<style>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
